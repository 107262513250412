import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import dayjs from 'dayjs';

import { Query } from '@/types';
import { BaseLayout, Meta } from '@/layouts';
import { DateFormat } from '@/constants';
import { stripTag } from '@/util/string';
import { ArchiveArticleDetail } from '@/molecules';

type ArchivePageProps = {
  data: Pick<Query, 'microcmsArchive'>;
};

const ArchivePage: React.FC<ArchivePageProps> = ({ data }) => {
  const title = data.microcmsArchive?.title;
  const publishDateString = data.microcmsArchive?.publishDate || data.microcmsArchive?.publishedAt;
  const mainVisual = data.microcmsArchive?.mainVisual?.url || '';
  const body = data.microcmsArchive?.body || '';
  const infoList = data.microcmsArchive?.info?.map((item) => ({
    head: item?.head || '',
    body: item?.body || '',
  }));

  const strippedBody = useMemo(() => stripTag(body || '').slice(0, 200), [body]);
  const publishDate = useMemo(
    () => dayjs(publishDateString).format(DateFormat.YEAR_MONTH_DATE_JP),
    [publishDateString],
  );

  return (
    <BaseLayout useHeader>
      <Meta title={title} description={strippedBody} ogImage={mainVisual} />
      <ArchiveArticleDetail
        title={title || ''}
        body={body || ''}
        publishDate={publishDate || ''}
        mainVisualUrl={mainVisual || ''}
        infoList={infoList}
      />
    </BaseLayout>
  );
};

export const query = graphql`
  query($slug: String!) {
    microcmsArchive(slug: { eq: $slug }) {
      title
      body
      publishDate
      publishedAt
      mainVisual {
        url
      }
      info {
        fieldId
        head
        body
      }
    }
  }
`;

export default ArchivePage;
